// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~simple-line-icons/scss/simple-line-icons.scss";
@import "~@coreui/coreui/scss/coreui.scss";
@import '~@coreui/icons/css/all.css';

// If you want to add something do it here
@import "custom";

.show-off {
    display: none;
}

.login-loader {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: darken;
    margin-left: -24px;
    margin-top: -24px;
    padding-top: 50%;
}

.wysiwyg-bordered {
  border: 1px solid #d9d9d9
}

// async typeahead custom layout
.dropdown-menu {
  overflow: hidden !important;
}

.rbt-highlight-text {
  background-color: rgba(255, 245, 158, 0.5) !important;
}


// edit cclass front remove icon
.ant-upload-list-item .ant-upload-list-item-card-actions-btn {
  opacity: 1;
  align-content: center;
  display: flex;
  background-color: none;
}

//remove icon color
.ant-upload-list-item-card-actions .anticon {
  color: red !important;
}

//hover collor
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: white;
}

//display fex to fix align
.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  display: flex;
}

.btn-export {
  float: right;
}

.buttonLink{
  width: 200px;
  display: flex;
  align-items: center; 
  justify-content: space-evenly;
}

.pagination {
  &.justify-content-start {
    justify-content: center !important;
  }
}
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

//Area do Professor
//input da modal 'meus cursos'
.rbt-input-multi.form-control{
  height: 100px !important;
  overflow: auto !important;
}
//modal do calendario
.ant-modal-mask {
  background-color: rgba(147, 146, 146, 0.02) !important;
}

// row - studentList
.height-row {
  height: 6vw;
}
// csv alunos
.csv-label {
  cursor: pointer;
  padding: 7px;
  color: #4f5d73;
  background-color: #ebedef;
  transition: all 0.5ms;
}

.csv-label:hover {
  background-color: #d6dade;
} 

//TeacherArea
.ant-tabs-nav-wrap {
 margin-left: 15px;
}

//MENTORSHIP

.col-link {
  display: grid;
  justify-content: center;
  align-items: center;
}

.col-content {
  display: grid;
  justify-content: center;
  align-items: center;
}




.title-tutoring-schedule {
  margin-bottom: 10px;
  margin-left: 5px;
}

.list-requested-tutoring {
  display: flex;
  background-color: #fafafa; 
  flex-wrap: wrap;  
}

.requested-tutoring {
  display: flex; 
  background-color: #fafafa;
  flex-wrap: wrap;
}


.tutoring-schedule {
  overflow: auto; 
  height: 720px; 
}

.custom-link {
  text-decoration: underline; 
  cursor: pointer;
}

.icon-custom-link {
  font-size: 33px;
}

.span-copy-link {
  color: blue; 
  text-decoration: none;
}

.icon-content-modal {
  margin-left: 30px; 
  cursor: pointer;
}

.title-content-modal {
  text-decoration: underline;
  cursor: pointer;
}

.col-scrool {
  height: 150px;
}

.buttons-form-item {
  gap: 368px;
  margin: 0px 53px;
}

.row-header {
  margin-bottom: 10; 
  margin-left: 5px;
}

.width100 {
  width: 100%;
}

.d-flex {
  display: flex;;
}

.m-right-10 {
  margin-right: 10px;
}

.h-200 {
  height: 200px;
}

.mb30 {
  margin-bottom: 30px;
}
//Mentoria
.title-header-tabPane {
  margin-top: 12px;
}

.teacher-link-content {
  width: 168px;
}

@media only screen and (max-width: 576px) {
  .teacher-link {
    justify-content: flex-start;
  }
}

// Desempenho em certificações

.editUserCertifications .addButton {
  display: flex;
  justify-content: center;
  align-content: center;
}

.button-group-aula {
  display: -webkit-inline-box;
}

// Turmas

.courseClass-id-badge {
  width: 30px;
}

// Aulas

.button-with-icon, .button-with-icon span {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */ }