// Stilos Custom aqui

.page-header-container {
  display: flex;
  justify-content: space-between;
}

.c-header.c-header-fixed {
  z-index: 2;
}
